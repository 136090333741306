import Choices from 'choices.js';
import { getElements } from 'js-utils/src/selector';

import init from '../base/init';
import choicesOptions from '../base/choices-options';
import choicesOptionsLegal from '../base/choices-options-legal';
import choicesOptionsLibrary from '../base/choices-options-library';
import choicesOptionsNoSort from '../base/choices-options-no-sort';
import choicesOptionsPurchaseFlow from '../base/choices-options-purchaseflow';
import choicesOptionsSavedSearches from '../base/choices-options-saved-searches';

const initGroup = (selector, options) => {
  const selects = getElements(selector);
  if(!selects.length) return;

  selects.forEach(select => {
    const classnames = ['choices'];

    if(select.classList.contains('input--padding'))
      classnames.push('choices--padding');

    if(select.classList.contains('input--bg'))
      classnames.push('choices--bg');

    options = {
      ...options,
      classNames: {
        containerOuter: classnames.join(' '),
      },
    };

    select.CHOICES_INSTANCE = new Choices(select, options);
  });
};

const initChoices = (scope) => {
  initGroup('.js--choices', choicesOptions, scope);
  initGroup('.js--choices-legal', choicesOptionsLegal, scope);
  initGroup('.js--choices-library', choicesOptionsLibrary, scope);
  initGroup('.js--choices-no-sort', choicesOptionsNoSort, scope);
  initGroup('.js--choices-purchaseflow', choicesOptionsPurchaseFlow, scope);
  initGroup('.js--choices-saved-searches', choicesOptionsSavedSearches, scope);
};

init(() => {
  initChoices(document);
});

export default initChoices;
