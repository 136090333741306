import A11yDialog from "a11y-dialog";
import { getElements, hasClosest } from "js-utils/src/selector";
import { delegateEventListener } from "js-utils/src/event";
import Cookies from "js-cookie";

import init from "../base/init";

init(() => {
  const elements = getElements(".a11ydialog");
  if (!elements.length) return;

  elements.forEach((element) => {
    const Dialog = new A11yDialog(
      element,
      document.querySelectorAll("body > *:not(.a11ydialog)")
    );

    element.A11yDialogInstance = Dialog;

    Dialog.on("show", () =>
      document.documentElement.classList.add("--disable-scroll")
    );
    Dialog.on("hide", () =>
      document.documentElement.classList.remove("--disable-scroll")
    );

    element.addEventListener("scroll-to-top", () => {
      element.querySelector(".a11ydialog__vcenter > div").scrollTop = 0;
    });

    // close on outside click
    if (element.classList.contains("js--a11ydialog-close-outside-click")) {
      const contentEl = element.querySelector(".js--a11ydialog-content");
      if (contentEl) {
        element.addEventListener("click", (e) => {
          if (!hasClosest(e.target, contentEl)) {
            Dialog.hide();
          }
        });
      }
    }

    // Open the Save to Library modal automatically, if the URL param includes it
    const saveToLibrary = document.querySelector(
      'input[name="save_to_library"]'
    );

    if (saveToLibrary) {
      if (
        element.id === "library-save-form-modal" &&
        saveToLibrary.value === "true"
      ) {
        if (element.A11yDialogInstance) element.A11yDialogInstance.show();
      }
    }

    // Open the migration modal, if the URL param includes it
    const showMigration = document.querySelector(
      'input[name="show_migration"]'
    );

    if (showMigration) {
      if (
        element.id === "sign-migration-dialog" &&
        showMigration.value === "true"
      ) {
        if (element.A11yDialogInstance) element.A11yDialogInstance.show();
      }
    }
  });

  delegateEventListener("[data-a11y-dialog-show]", "click", (e) => {
    e.preventDefault();
  });
});
